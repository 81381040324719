<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newItem'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add New Item</h5>
            <a class="close" @click="$store.dispatch('modalClose', 'addNewItem')" title="Close">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <div>
            <form
              @submit.prevent="submit"
              method="post"
              class="bg-theam-secondary"
              enctype="multipart/form-data"
            >
              <div class="modal-body row">
                <div class="col-md-6 pd-r-7-5">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="group mb-3">
                        <label class="group-label">Basic Information</label>
                        <div class="group-attribute-container">
                          <div class="row">
                            <div class="col-md-5 mt-0 mb-1">
                              <input
                                type="file"
                                id="Item_image"
                                style="display: none"
                                @change="base64Image"
                              />
                              <label for="Item_image" class="popup-img-container">
                                <span>
                                  Browse
                                  <br />Image
                                </span>
                                <div class="popup_img">
                                  <img
                                    class="popup_img"
                                    :src="
                                    formData.image
                                      ? formData.image
                                      : '/assets/img/logosmall.png'
                                  "
                                  />
                                  <div id="change_img">
                                    Change
                                    <br />Image
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div class="col-md-7 pl-0 pd-t-30 pb-0">
                              <div class="row">
                                <div class="col-md-5 mr-0 pr-0">Item Code</div>
                                <div class="col-md-7 ml-0 pl-0">
                                  <input
                                    class="form-control"
                                    placeholder="Item Code"
                                    v-model="formData.item_code"
                                    @input="upper($event)"
                                    @keyup="
                                  itemCodeValidation(formData.item_code,0)"
                                  />
                                </div>
                                <span
                                  class="unique-message"
                                  v-if="code_error == true"
                                >Item Code should be unique</span>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-2">
                            <div class="col-md-5 mr-0 pr-0">Item Name *</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Item Name"
                                v-model="formData.name"
                                required
                              />
                            </div>
                          </div>
                          <div class="row mb-2">
                            <div class="col-md-5 mr-0 pr-0">Item Category *</div>
                            <div class="col-md-7 input-group ml-0 pl-0">
                              <select
                                class="form-control select2"
                                v-model="formData.group"
                                required
                              >
                                <option value disabled>Select Item Category</option>
                                <option
                                  v-for="(group, index) in itemElements.group"
                                  :key="index"
                                  :value="group.id"
                                >{{ group.title }}</option>
                              </select>
                              <!-- <div
                                class="input-group-prepend"
                                v-if="checkSinglePermission('create_item_group')"
                              >
                                <button
                                  @click="
                                  $store.dispatch('modalWithMode', {
                                    id: 'newItemGroup',
                                    mode: 'create',
                                  })
                                "
                                  type="button"
                                  class="btn-primary input-group-add-btn"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div> -->
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5 mr-0 pr-0">Brand</div>
                            <div class="col-md-7 input-group ml-0 pl-0">
                              <select class="form-control" v-model="formData.brand_id">
                                <option value="">Select Brand</option>
                                <option
                                  v-for="(brand, index) in itemElements.brands"
                                  :key="index"
                                  :value="brand.id"
                                >{{ brand.name }}</option>
                              </select>
                              <!-- <div
                                class="input-group-prepend"
                                v-if="checkSinglePermission('create_brand')"
                              >
                                <button
                                  @click="
                                  $store.dispatch('modalWithMode', {
                                    id: 'addNewBrand',
                                    mode: 'create',
                                  })
                                "
                                  type="button"
                                  class="btn-primary input-group-add-btn"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="group"
                        id="description"
                        v-if="authData.general_setting.vatable==1"
                      >
                        <label class="group-label">Description</label>
                        <div class="group-attribute-container">
                          <div class="row">
                            <div class="col-md-12">
                              <textarea
                                class="form-control"
                                type="text"
                                name="description"
                                style="height: 2.85rem"
                                v-model="formData.description"
                                v-if="authData.general_setting.org_type==1"
                              ></textarea>
                              <textarea
                                class="form-control"
                                type="text"
                                name="description"
                                style="height: 2.35rem"
                                v-model="formData.description"
                                v-if="authData.general_setting.org_type==0"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="group" v-if="authData.general_setting.vatable==0">
                        <label class="group-label">Unit Type</label>
                        <div class="group-attribute-container">
                          <div class="row">
                            <div class="col-md-5 mr-0 pr-0">Unit *</div>
                            <div class="col-md-7 input-group ml-0 pl-0">
                              <select class="form-control" v-model="formData.unit_type" required>
                                <option value disabled>Unit Type</option>
                                <option
                                  v-for="(
                                  unit_type, index
                                ) in itemElements.unit_type"
                                  :key="index"
                                  :value="unit_type.id"
                                >{{ unit_type.title }}</option>
                              </select>
                              <!-- <div
                                class="input-group-prepend"
                                v-if="checkSinglePermission('create_item_unit')"
                              >
                                <button
                                  @click="
                                  $store.dispatch('modalWithMode', {
                                    id: 'newUnitType',
                                    mode: 'create',
                                  })
                                "
                                  type="button"
                                  class="btn-primary input-group-add-btn"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pd-l-7-5">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="group mb-3">
                        <label class="group-label">Stock Setting</label>
                        <div class="group-attribute-container">
                          <div
                            class="from-group-check"
                            v-if="authData.general_setting.org_type==1 && showStockRequired"
                          >
                            <input
                              class="group-check-box"
                              type="checkbox"
                              v-model="formData.stock_required"
                              id="stock_required"
                              value="1"
                            />
                            <label for="stock_required" class="ml-2">Stock Required</label>
                          </div>
                          <div
                            class="group-note mb-2"
                            v-if="authData.general_setting.org_type==1 && showStockRequired"
                          >
                            <span>Note:</span> Please Uncheck the option if Stock
                            is not required to item
                          </div>
                          <div class="row mb-2" v-if="formData.stock_required==1">
                            <div class="col-md-5 mr-0 pr-0">Minimum Stocks</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="number"
                                step="any"
                                min="0"
                                placeholder="Min for Notification"
                                class="form-control pr-4"
                                v-model="formData.min_stock"
                              />
                              <span class="input-inside-icon">
                                <i class="fas fa-info-circle pd-t-3"></i>
                              </span>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="formData.stock_required==1 && itemElements.item_setting.expiry==1"
                          >
                            <div class="col-md-5 mr-0 pr-0">Expiry Days</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="number"
                                step="any"
                                min="0"
                                placeholder="Notification before expity"
                                class="form-control pr-4"
                                v-model="formData.day_count"
                              />
                              <span class="input-inside-icon">
                                <i class="fas fa-info-circle pd-t-3"></i>
                              </span>
                            </div>
                          </div>
                          <div class="row mb-2" v-if="formData.stock_required==0">
                            <div class="col-md-5 mr-0 pr-0">Sales Rate</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="number"
                                step="any"
                                min="0"
                                class="form-control"
                                placeholder="Rate"
                                v-model="formData.sales_rate"
                              />
                              <!-- <input type="number" step="any" class="form-control" placeholder="Rate" v-model="formData.sales_rate" disabled v-else/> -->
                            </div>
                          </div>
                          <div class="row" v-if="formData.stock_required==0">
                            <div class="col-md-5 mr-0 pr-0">Discount</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <div class="input-group">
                                <input
                                  type="number"
                                  step="any"
                                  min="0"
                                  class="form-control"
                                  placeholder="0.00"
                                  @blur="discountAmount"
                                  v-model="formData.discount"
                                />
                                <div class="input-group-prepend">
                                  <span class="input-group-text pl-1 pt-1" style="min-width: 0px"> % </span>
                                </div>
                                <input
                                  type="number"
                                  step="any"
                                  min="0"
                                  class="form-control wd-20pimp"
                                  placeholder="0.00"
                                  @blur="discountRate"
                                  v-model="formData.discount_amount"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="group"
                        id="description"
                        v-if="authData.general_setting.vatable==0"
                      >
                        <label class="group-label">Description</label>
                        <div class="group-attribute-container">
                          <div class="row">
                            <div class="col-md-12">
                              <textarea
                                class="form-control"
                                type="text"
                                name="description"
                                style="height: 6.75rem"
                                v-model="formData.description"
                                v-if="authData.general_setting.org_type==0"
                              ></textarea>
                              <textarea
                                class="form-control"
                                type="text"
                                name="description"
                                style="height: 4.29rem"
                                v-model="formData.description"
                                v-if="authData.general_setting.org_type==1"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="group mb-3" v-if="authData.general_setting.vatable==1">
                        <label class="group-label">Unit Type</label>
                        <div class="group-attribute-container">
                          <div class="row">
                            <div class="col-md-5 mr-0 pr-0">Unit *</div>
                            <div class="col-md-7 input-group ml-0 pl-0">
                              <select class="form-control" v-model="formData.unit_type" required>
                                <option value disabled>Select Unit Type</option>
                                <option
                                  v-for="(
                                  unit_type, index
                                ) in itemElements.unit_type"
                                  :key="index"
                                  :value="unit_type.id"
                                >{{ unit_type.title }}</option>
                              </select>
                              <!-- <div
                                class="input-group-prepend"
                                v-if="checkSinglePermission('create_item_unit')"
                              >
                                <button
                                  @click="
                                  $store.dispatch('modalWithMode', {
                                    id: 'newUnitType',
                                    mode: 'create',
                                  })
                                "
                                  type="button"
                                  class="btn-primary input-group-add-btn"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="group" v-if="authData.general_setting.vatable==1">
                        <label class="group-label">VAT Settings</label>
                        <div class="group-attribute-container">
                          <div class="from-group-check">
                            <input
                              class="group-check-box"
                              type="checkbox"
                              name="expense_type"
                              id="vatable"
                              value="0"
                              v-model="formData.vat_applicable"
                            />
                            <label for="vatable" class="ml-2">VAT Applicable</label>
                          </div>
                          <div class="group-note">
                            <span>Note:</span> Please Check the option if VAT is
                            applicable to item
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pd-l-7-5 mt-3">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="group mb-3" v-if="itemElements.item_setting.extra_detail==1">
                        <label class="group-label">Extra Fields</label>
                        <div class="group-attribute-container" v-for="(field, index) in itemElements.extra_fields" :key="index">
                          <div class="row mb-2">
                            <div class="col-md-5 mr-0 pr-0">{{field.title}}</div>
                            <div class="col-md-7 ml-0 pl-0">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Value"
                                :id="'extra-'+field.slug"
                                value=""
                                @keyup="extraFieldSet({ ...field })"
                                @change="extraFieldSet({ ...field })"
                              />
                              <!-- <input type="number" step="any" class="form-control" placeholder="Rate" v-model="formData.sales_rate" disabled v-else/> -->
                            </div>
                        </div>
                        </div>
                      </div>
                      </div>
                    </div>
                </div>
                <div class="col-md-12 text-right mt-3">
                  <span class="unique-message" v-if="code_error == true">Item Code should be unique</span>
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary"
                    :disabled="disableSubmitButton"
                  >
                    <span v-if="code_error">Submit</span>
                    <span v-else>{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  props: {
    showStockRequired: {
      type: Boolean,
      default: true,
    },
  },
  components: {
  },
  data() {
    return {
      formData: {
        item_id: "",
        available: 0,
        image: "",
        name: "",
        group: "",
        min_stock: "",
        day_count: "",
        unit_type: "",
        brand_id: "",
        stock_required: 0,
        sales_rate: null,
        discount: null,
        discount_amount: null,
        vat_applicable: 0,
        description: "",
        vatable: "",
        data_from: "",
        extra_fields:[],
      },
      unique_item_code: true,
      code_error: false,
      disableSubmitButton: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
      "authData",
    ]),
    ...mapGetters("items", ["itemLists", "itemElements"]),
  },
  mounted() {
    let pageName = this.$route.path.split("/");
    this.formData.data_from = pageName[1];
  },
  // filters: {
  //   capitalize: function (value) {
  //     if (!value) return "";
  //     value = value.toString();
  //     return value.charAt(0).toUpperCase() + value.slice(1);
  //   },
  // },
  methods: {
    upper(e) {
          e.target.value = e.target.value.toUpperCase()
      },
    base64Image(e) {
      this.formData.image = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.image = e.target.result;
      };
    },
    submit() {
      if (this.modalMode == "create") {
        this.disableSubmitButton = true;
        Services.createNewItem(this.formData)
          .then((res) => {
            this.$store.commit("items/setItemLists", res.data.data);
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
            this.disableSubmitButton = false;
            this.$emit("parent-event");
          })
          .catch((err) => {
            this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              console.log(err);
            }
          });
      } else if (this.modalMode == "edit") {
        this.$store.commit("setApiUrl", `api/item/${this.formData.id}`);
        this.$store.commit("updateData", this.formData);
      }
    },
    extraFieldSet(details){
        // let value = document.getElementById(`extra-${details.slug}`).value;
        // if (value) {
        //   this.formData.extra_fields.push({
        //     slug: details.slug,
        //     value: value,
        //   });
        // }
      let index = this.formData.extra_fields.findIndex(
        (x) => x.slug === details.slug
      );
      if (index >= 0) {
        let value = document.getElementById(`extra-${details.slug}`).value;
        if (value) {
          this.formData.extra_fields[index].value = value;
        } else {
          this.formData.extra_fields[index].value ="";
        }
      } else {
        let value = document.getElementById(`extra-${details.slug}`).value;
        if (value) {
          this.formData.extra_fields.push({
            id:details.id,
            title:details.title,
            slug: details.slug,
            value: value,
          });
        }
      }
    },
    clearData() {
      this.formData.id = "";
      this.formData.item_code = "";
      this.formData.image = "";
      this.formData.name = "";
      this.formData.group = "";
      this.formData.min_stock = "";
      this.formData.day_count = "";
      this.formData.unit_type = "";
      this.formData.sales_rate = null;
      this.formData.discount = null;
      this.formData.discount_amount = null;
      this.formData.vat_applicable = 0;
      this.formData.description = "";
      this.formData.brand_id = "";
      this.formData.stock_required = 0;
      this.formData.unit_type = "";
      this.unique_item_code = true;
      this.code_error = false;
      this.disableSubmitButton = false;
      this.loading = true;
    },
    discountRate() {
      if (this.formData.sales_rate > 0) {
        this.formData.discount =
          (this.formData.discount_amount / this.formData.sales_rate) * 100;
      }
    },
    discountAmount() {
      if (this.formData.sales_rate > 0) {
        this.formData.discount_amount =
          (this.formData.sales_rate * this.formData.discount) / 100;
      }
    },
    getData() {
      this.$emit("parent-event");
    },
    itemCodeValidation: _.debounce(function (code, id) {
      if (code != "") {
        code = code.toUpperCase();
        let values = {
          code: code,
          id: id,
        };
        Services.validateItemCode(values)
          .then((res) => {
            this.disableSubmitButton = res.data.data;
            this.code_error = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }else{
        this.disableSubmitButton = false;
            this.code_error = false;
      }
    }, 2000),
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },
  watch: {
    modalId(value) {
      if (value == "newItem") {
        Services.getItemElements()
          .then((res) => {
            this.$store.commit("items/setItemElements", res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    modalMode(value) {
      if (value == "create") {
        this.clearData();
      }
    },
    "formData.stock_required"(value) {
      if (value == 0) {
        this.formData.sales_rate = null;
        this.formData.discount = null;
        this.formData.discount_amount = null;
      } else if (value == 1) {
        this.formData.min_stock = null;
        this.formData.day_count = null;
      }
    },
  },
};
</script>
<style>
.unique-message {
  color: #db4437;
}
</style>
