import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";
class ItemService{
    getItemLists(size){
        const url = `api/items/size/${size}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getItemElements(){
        const url = `api/item/elements`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    validateItemCode(value){
        const url = `api/item/unique/code/validate`
        const values = {
            'item_code':value.code,
            'id':value.id,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,values);
    }
    createNewItem(formData){
        const url = `api/item/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    editItem(formData,id){
        const url = `api/item/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    getFilteredItemLists(param){
        const url = `api/item/filter`
        const params = {
            'page-index':param.size,
            'offset':param.offset,
            'group':param.group,
            'unit_type':param.unit_type,
            'searched':param.searched,
            'brand':param.brand,
            'vat':param.vat,
            'stock':param.stock,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    createNewItemGroup(formData){
        const url = `api/item/group/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    deleteItem(id){
        const url = `api/item/${id}`;
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
    //settings
    updateItemSetting(formdata,id){
        const url = `api/settings/item/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    getSettings(){
        const url = `api/settings/item`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createExtraField(formdata){
        const url = `api/settings/item/extra/field/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    updateExtraField(formdata,id){
        const url = `api/settings/item/extra/field/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    deleteExtraField(id){
        const url = `api/settings/item/extra/field/delete/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
}
export default new ItemService();